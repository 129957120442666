.translations-list-label {
    display: block;

    @media (min-width: 576px) {
        display: inline-block;
        flex: 0 0 30%;
    }
}

.translations-list-item  input{
    width: 100%;

    @media (min-width: 576px) {
        flex-grow: 1;
    }
}